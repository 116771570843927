body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, .pointer {
  cursor: pointer !important;
}

.divider {
  margin-top: $spacer;
  margin-bottom: $spacer;
  height: 1px;
  overflow: hidden;
  background: $gray-200;
}

:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.app-page-title {
  background: rgba(255, 255, 255, 0.45);
  padding: $grid-gutter-width $grid-gutter-width 4px;
  margin: (-$grid-gutter-width) (-$grid-gutter-width) $grid-gutter-width;

  .page-title-heading {
    font-size: $h5-font-size;
    font-weight: 400;
    display: flex;
    align-content: center;
    align-items: center;
  }

  .page-title-heading,
  .page-title-subheading {
    margin: 0;
    padding: 0;
  }

  .page-title-subheading {
    padding: 3px 0 0;
    font-size: $font-size-base;
    opacity: .6;
  }
}

.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-control[readonly] {
  background-color: #fff;
}


.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-left: 5px;
  position: unset;
}

.logo img {
  max-width: 330px;
  width: 60%;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #737373;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.ngx-pagination {
  margin-bottom: 0 !important;
}

.ngx-pagination .current {
  background: #F57C21 !important;
}


.app-login-box {
  h4 {
    margin-bottom: 1.5rem;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
